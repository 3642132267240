import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      name: "ComingSoon",
      component: () => import("@/views/comingSoon/ComingSoon.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/home/Home.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/aboutUs",
      name: "AboutUs",
      component: () => import("@/views/aboutUs/AboutUs.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/management",
      name: "Management",
      component: () => import("@/views/management/Management.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/contactUs",
      name: "ContactUs",
      component: () => import("@/views/contactUs/ContactUs.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/carRental",
      name: "CarRental",
      component: () => import("@/views/rental/Rental.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/interpreter",
      name: "Interpreter",
      component: () => import("@/views/interpreter/Interpreter.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/hotel",
      name: "Hotel",
      component: () => import("@/views/hotel/Hotel.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/safeTravel",
      name: "SafeTravel",
      component: () => import("@/views/travel/Travel.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/tourism",
      name: "Tourism",
      component: () => import("@/views/tourism/Tourism.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/insurance",
      name: "Insurance",
      component: () => import("@/views/insurance/Insurance.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/hospital",
      name: "Hospital",
      component: () => import("@/views/hospital/Hospital.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/treatment",
      name: "Treatment",
      component: () => import("@/views/treatment/Treatment.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/termsOfServices",
      name: "TermsOfServices",
      component: () => import("@/views/support/TermsOfServices.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/privacyPolicy",
      name: "PrivacyPolicy",
      component: () => import("@/views/support/PrivacyPolicy.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/cart",
      name: "Cart",
      component: () => import("@/views/cart/Cart.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/visaAssistance",
      name: "VisaAssistance",
      component: () => import("@/views/visaAssistance/VisaAssistance.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/signUp",
      name: "SignUp",
      component: () => import("@/views/auth/SignUp.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/allDoctor",
      name: "AllDoctor",
      component: () => import("@/views/doctor/AllDoctor.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/blog",
      name: "Blog",
      component: () => import("@/views/blog/SingleBlog.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/allBlog",
      name: "AllBlog",
      component: () => import("@/views/blog/AllBlog.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/user",
      name: "User",
      component: () => import("@/views/user/User.vue"),
      meta: {
        pageTitle: "User",
        breadcrumb: [
          {
            text: "User",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_user",
      },
    },
    {
      path: "/role",
      name: "Role",
      component: () => import("@/views/role/Role.vue"),
      meta: {
        pageTitle: "Role",
        breadcrumb: [
          {
            text: "Role",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_role",
      },
    },
    {
      path: "/add_department",
      name: "AddDepartment",
      component: () => import("@/views/admin/department/Department.vue"),
      meta: {
        pageTitle: "Department",
        breadcrumb: [
          {
            text: "Department",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_department",
      },
    },

    {
      path: "/add_doctor",
      name: "AddDoctor",
      component: () => import("@/views/admin/doctor/Doctor.vue"),
      meta: {
        pageTitle: "Doctor",
        breadcrumb: [
          {
            text: "Doctor",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_doctor",
      },
    },
    {
      path: "/doctor_dashboard",
      name: "DoctorDashboard",
      component: () => import("@/views/admin/doctor/DoctorDashboard.vue"),
      meta: {
        pageTitle: "Doctor Dashboard",
        breadcrumb: [
          {
            text: "Doctor Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_doctor_dashboard",
      },
    },

    {
      path: "/add_procedure",
      name: "AddProcedure",
      component: () => import("@/views/admin/procedure/Procedure.vue"),
      meta: {
        pageTitle: "Procedure",
        breadcrumb: [
          {
            text: "Procedure",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_procedure",
      },
    },

    {
      path: "/add_hospital",
      name: "AddHospital",
      component: () => import("@/views/admin/hospital/Hospital.vue"),
      meta: {
        pageTitle: "Hospital",
        breadcrumb: [
          {
            text: "Hospital",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_hospital",
      },
    },

    {
      path: "/add_hotel",
      name: "AddHotel",
      component: () => import("@/views/admin/hotel/Hotel.vue"),
      meta: {
        pageTitle: "Hotel",
        breadcrumb: [
          {
            text: "Hotel",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_hotel",
      },
    },

    {
      path: "/add_room",
      name: "AddRoom",
      component: () => import("@/views/admin/room/Room.vue"),
      meta: {
        pageTitle: "Room",
        breadcrumb: [
          {
            text: "Room",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_room_category",
      },
    },

    {
      path: "/add_rental_company",
      name: "AddRentalCompany",
      component: () => import("@/views/admin/rentalCompany/RentalCompany.vue"),
      meta: {
        pageTitle: "Rental Company",
        breadcrumb: [
          {
            text: "Rental Company",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_rental_company",
      },
    },

    {
      path: "/add_rental_car",
      name: "AddRentalCar",
      component: () => import("@/views/admin/rentalCar/RentalCar.vue"),
      meta: {
        pageTitle: "Rental Car",
        breadcrumb: [
          {
            text: "Rental Car",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_rental_car",
      },
    },

    {
      path: "/add_security",
      name: "AddSecurity",
      component: () => import("@/views/admin/security/Security.vue"),
      meta: {
        pageTitle: "Security Services",
        breadcrumb: [
          {
            text: "Security Services",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_security_package",
      },
    },

    {
      path: "/add_interprator_company",
      name: "AddInterpratorCompany",
      component: () =>
        import("@/views/admin/interpratorCompany/InterpratorCompany.vue"),
      meta: {
        pageTitle: "Interprator Company",
        breadcrumb: [
          {
            text: "Interprator Company",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_interprator_company",
      },
    },

    {
      path: "/add_interprator_serice",
      name: "AddInterpratorService",
      component: () =>
        import("@/views/admin/interpratorService/InterpratorService.vue"),
      meta: {
        pageTitle: "Interprator Service",
        breadcrumb: [
          {
            text: "Interprator Service",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_interprator_service",
      },
    },

    {
      path: "/tourist_attraction",
      name: "AddTourist",
      component: () =>
        import("@/views/admin/tourist_attraction/TouristAttraction.vue"),
      meta: {
        pageTitle: "Tourist Attraction",
        breadcrumb: [
          {
            text: "Tourist Attraction",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_tourist_attraction",
      },
    },
    {
      path: "/add_tourist_trip",
      name: "AddTouristTrip",
      component: () => import("@/views/admin/tourist_trip/TouristTrip.vue"),
      meta: {
        pageTitle: "Tourist Trip",
        breadcrumb: [
          {
            text: "Tourist Trip",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_tourist_trip",
      },
    },

    {
      path: "/patient_dashboard",
      name: "PatientDashboard",
      component: () => import("@/views/admin/patient/Dashboard.vue"),
      meta: {
        pageTitle: "Patient Dashboard",
        breadcrumb: [
          {
            text: "Patient Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_patient_dashboard",
      },
    },

    {
      path: "/payment_successful",
      name: "PaymentSuccessful",
      component: () =>
        import("@/views/admin/patient/payment_pages/PaymentSuccessful.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        permission: "show_patient_dashboard",
      },
    },

    {
      path: "/payment_unsuccessful",
      name: "PaymentUnsuccessful",
      component: () =>
        import("@/views/admin/patient/payment_pages/PaymentUnsuccessful.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        permission: "show_patient_dashboard",
      },
    },

    {
      path: "/patient",
      name: "Patient",
      component: () => import("@/views/admin/csr/Patient.vue"),
      meta: {
        pageTitle: "Patient",
        breadcrumb: [
          {
            text: "Patient",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "read_patient",
      },
    },

    {
      path: "/create_order",
      name: "CreateOrder",
      component: () => import("@/views/admin/csr/CreateOrder.vue"),
      meta: {
        pageTitle: "CSR Panel",
        breadcrumb: [
          {
            text: "Create Order",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_create_order",
      },
    },

    {
      path: "/bookings",
      name: "ShowBooking",
      component: () => import("@/views/admin/csr/Booking.vue"),
      meta: {
        pageTitle: "Bookings",
        breadcrumb: [
          {
            text: "Bookings",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_create_order",
      },
    },

    {
      path: "/visa_assistance",
      name: "AddVisaAssistance",
      component: () =>
        import("@/views/admin/visaAssistance/VisaAssistance.vue"),
      meta: {
        pageTitle: "Visa Assistance",
        breadcrumb: [
          {
            text: "Visa Assistance",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_visa_assistance",
      },
    },

    {
      path: "/insurance",
      name: "AddInsurance",
      component: () => import("@/views/admin/insurance/Insurance.vue"),
      meta: {
        pageTitle: "Insurance",
        breadcrumb: [
          {
            text: "Insurance",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_insurance",
      },
    },

    {
      path: "/blog_category",
      name: "AddBlogCategory",
      component: () => import("@/views/admin/blog_category/BlogCategory.vue"),
      meta: {
        pageTitle: "Blog Category",
        breadcrumb: [
          {
            text: "Blog Category",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_blog_category",
      },
    },

    {
      path: "/blog",
      name: "AddBlog",
      component: () => import("@/views/admin/blog/Blog.vue"),
      meta: {
        pageTitle: "Blog",
        breadcrumb: [
          {
            text: "Blog",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_blog",
      },
    },

    {
      path: "/user_profile",
      name: "UserProfile",
      component: () => import("@/views/userprofile/Userprofile.vue"),
      meta: {
        pageTitle: "User Profile",
        breadcrumb: [
          {
            text: "User Profile",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "update_user",
      },
    },

    {
      path: "/change_password",
      name: "ChangePassword",
      component: () => import("@/views/userprofile/Changepassword.vue"),
      meta: {
        pageTitle: "Change Password",
        breadcrumb: [
          {
            text: "Change Password",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "update_user",
      },
    },

    {
      path: "/consultations",
      name: "ShowConsultations",
      component: () => import("@/views/admin/csr/Consultation.vue"),
      meta: {
        pageTitle: "Consultations",
        breadcrumb: [
          {
            text: "Consultations",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_create_order",
      },
    },
    {
      path: "/add_gallery",
      name: "AddGallery",
      component: () => import("@/views/admin/gallery/Gallery.vue"),
      meta: {
        pageTitle: "Gallery",
        breadcrumb: [
          {
            text: "Gallery",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_gallery",
      },
    },
    {
      path: "/faq",
      name: "AddFAQ",
      component: () => import("@/views/admin/faq/FAQ.vue"),
      meta: {
        pageTitle: "FAQ",
        breadcrumb: [
          {
            text: "FAQ",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_faq",
      },
    },

    {
      path: "/quote",
      name: "Quote",
      component: () => import("@/views/admin/quote/Quote.vue"),
      meta: {
        pageTitle: "Quote",
        breadcrumb: [
          {
            text: "Quote",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_quote",
      },
    },
    {
      path: "/referralCompany",
      name: "ReferralCompany",
      component: () =>
        import("@/views/admin/referralCompany/ReferralCompany.vue"),
      meta: {
        pageTitle: "ReferralCompany",
        breadcrumb: [
          {
            text: "ReferralCompany",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_company",
      },
    },

    {
      path: "/testimonial",
      name: "AddTestimonial",
      component: () => import("@/views/admin/testimonial/Testimonial.vue"),
      meta: {
        pageTitle: "Testimonial",
        breadcrumb: [
          {
            text: "Testimonial",
            active: true,
          },
        ],
        requiresAuth: true,
        permission: "show_testimonial",
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    localStorage.setItem("lastRoute", from.name);
  }
  next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
